import React from "react";
import { RegistrationForm } from "./registarationForm";

import "./style.css";

export const Registration = () => {
  return (
    <div className={"registration__page__wrapper"}>
      <RegistrationForm />
    </div>
  );
};

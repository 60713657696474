import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    fullScreenSize:false
}

export const screenSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setScreenSize: (_,action)=>{
            _.fullScreenSize = action.payload
        }
    },
})

export const { setScreenSize } = screenSlice.actions;

export default screenSlice.reducer;

export const SelectsOptions = [
  { value: "dissolve", label: "Dissolve" },
  { value: "fadeInOut", label: "Fade in/out" },
  // { value: "fadeIn", label: "Fade in" },
  // { value: "flash", label: "Flash" },
  // { value: "rubberBand", label: "Rubber Band" },
  // { value: "shakeX", label: "Shake X" },
  // { value: "shakeY", label: "Shake Y" },
  // { value: "wobble", label: "Wobble" },
  // { value: "jello", label: "Jello" },
  // { value: "heartBeat", label: "Heart Beat" },
];

export const viewSelectOptions = [
  { value: "grid", label: "Grid view" },
  { value: "list", label: "List view" },
];

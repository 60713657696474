import image from "./gipfy.gif";
import image2 from "./giphy 2.gif";
import image3 from "./giphy 3.gif";
import image4 from "./giphy 4.gif";
import image5 from "./giphy 5.gif";
import image6 from "./giphy 6.gif";
import image7 from "./giphy 7.gif";
import image8 from "./giphy 8.gif";
import image9 from "./giphy 9.gif";
import image10 from "./giphy 10.gif";

export const randomFolderImage = [
  image,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
];

import React, {useEffect} from "react";
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import {useDispatch} from "react-redux";
import {setScreenSize} from "./store/slices/screenSlice";

export const App = () => {

    const dispatch = useDispatch()

    const [size, setSize] = React.useState(false)

    const handleResize = (e) => {
        if (!e.metaKey && !e.ctrlKey && !e.altKey && !e.shiftKey) {
            if (
                window.innerWidth === window.screen.width &&
                window.innerHeight === window.screen.height
            ) {
                dispatch(setScreenSize(true));
                setSize(true)
            } else {
                dispatch(setScreenSize(false));
                setSize(false)
            }
        }
    };


    useEffect(() => {

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [window.document.innerWidth, window.document.innerHeight]);

    const keyPress = (e) => {
        if (e.key === "F11") {
            dispatch(setScreenSize(!test));
            setSize(prevState => !test)
        }
    }

    useEffect(() => {

        window.document.addEventListener("keyup", keyPress);

        return () => {
            window.document.removeEventListener("keyup", keyPress);
        };
    }, [size])


    return <RouterProvider router={router}/>;
};

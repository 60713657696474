export const routes = {
  login: "/",
  dashboard: "dashboard",
  favorite: "favorite",
  mostFav: "most-favorite",
  gifs: "/dashboard/:folder",
  carousel: "/dashboard/:folder/carousel",
  reg: "/registration",
  reset: "/reset",
  error: "*",
  publicCarousel: "/:userId/:folderId/carousel",
};
